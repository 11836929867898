import React from "react"
import styled from "styled-components"

import globalVariables from "../../../globalVariables"

const TitleStyled = styled.h4`
  position: relative;
  font-weight: 700;
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme.greyDarker};
  text-align: ${props => (props.position ? props.position : null)};
  text-transform: uppercase;
  margin: ${props => (props.margin ? props.margin : null)};
  transition: all 0.2s ease-in-out;
  &.title2 {
    font-size: 22px;
  }
  &.title3 {
    font-size: 20px;
  }
  &.title4 {
    font-size: 16px;
    text-transform: initial;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    &.title4 {
      font-size: 20px;
    }
  }
`

const TitleH4 = ({
  position,
  color,
  className,
  traitWidth,
  traitLeft,
  traitColor,
  margin,
  children,
}) => (
  <TitleStyled
    position={position}
    color={color}
    className={className}
    traitWidth={traitWidth}
    traitLeft={traitLeft}
    margin={margin}
    traitColor={traitColor}
  >
    {children}
  </TitleStyled>
)

export default TitleH4
