import { css } from "styled-components"
import globalVariables from "../../globalVariables"
import theme from "../../theme"

export const getInputStyles = props => {
  let cursor = "text"
  // Use box-shadow with inset instead of border to avoid animation jerk.
  // let borderWidth = `1px`
  let width = ""
  let borderColor = "#aeaeae"

  /**
   * Component state modifiers
   */

  if (props.isHover) {
    borderColor = theme.primary
  }

  if (props.isFocus) {
    // borderWidth = "2px"
    borderColor = theme.primary
  }

  if (props.disabled) {
    cursor = "not-allowed"
  }

  if (props.fullWidth) {
    width = "100%"
  }

  return css`
    cursor: ${cursor};
    border: solid 1px ${borderColor};
    background: white;
    color: ${props => props.theme.headerColor};
    box-sizing: border-box;
    display: flex;
    flex: 1 0 auto;
    width: ${width};
    height: 55px;
    max-width: 100%;
    padding: 18px 25px;
    align-items: center;
    border-radius: ${globalVariables.borderRadius};
    line-height: 1.42857;
    word-wrap: break-word;
    outline: none;
    transition: all 0.2s ease-in-out;
    transition-delay: 50ms;
  `
}

/**
 * Get the styles for the input inner component
 */
export const getInnerStyle = props => {
  return css`
    color: inherit;
    &:-webkit-autofill {
      box-shadow: 0 0 0 30px ${props => props.theme.white} inset;
    }

    &::placeholder {
      color: ${props => props.theme.bodyColor};
      /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif; */
    }
  `
}
