import React from "react"
import styled from "styled-components"

import globalVariables from "../../../globalVariables"

const TitleStyled = styled.h2`
  position: relative;
  font-weight: 700;
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme.greyDarker};
  text-align: ${props => (props.position ? props.position : null)};
  text-transform: uppercase;
  margin: ${props => (props.margin ? props.margin : null)};
  transition: all 0.2s ease-in-out;
  &.title1 {
    font-size: 25px;
    line-height: 1.4;

    &.trait::after {
      content: "";
      position: absolute;
      bottom: -26px;
      left: -30px;
      height: 3px;
      width: 270px;
      background: ${props =>
        props.traitColor
          ? props.theme[props.traitColor]
          : props.color
          ? props.theme[props.color]
          : props.theme.primary};
    }
  }
  &.title2 {
    font-size: 22px;
  }
  &.title3 {
    font-size: 20px;
  }
  &.title4 {
    font-size: 16px;
    text-transform: initial;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    &.title1 {
      font-size: 28px;
      &.trait::after {
        bottom: -22px;
        left: ${props => (props.traitLeft ? props.traitLeft : "-165px")};
        width: ${props => (props.traitWidth ? props.traitWidth : "350px")};
      }
    }
    &.title4 {
      font-size: 20px;
    }
  }
`

const TitleH2 = ({
  position,
  color,
  className,
  traitWidth,
  traitLeft,
  traitColor,
  margin,
  children,
}) => (
  <TitleStyled
    position={position}
    color={color}
    className={className}
    traitWidth={traitWidth}
    traitLeft={traitLeft}
    margin={margin}
    traitColor={traitColor}
  >
    {children}
  </TitleStyled>
)

export default TitleH2
