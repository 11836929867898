import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import { getInputStyles, getInnerStyle } from "../Input/style"

const TextareaWrapper = styled.div`
  ${getInputStyles};
  padding: 0;
  height: auto;
`

// The actual textarea element
const TextareaElement = styled.textarea`
  flex: 1 1 100%;
  background: none;
  border: none;
  outline: none;
  transition: box-shadow 200ms ease-in-out;
  transition-delay: 50ms;
  padding: 18px 25px;
  ${getInnerStyle};
  min-height: 150px;
`

const Textarea = ({
  defaultValue,
  placeholder,
  type,
  value,
  name,
  autofocus,
  disabled,
  fullWidth,
  handleChange,
  onKeyDown,
  onKeyPress,
  id,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const textareaRef = useRef()

  useEffect(() => {
    if (textareaRef && autofocus) {
      textareaRef.current.focus()
    }
  }, [])

  const onFocus = e => {
    setIsFocus(true)
  }

  const onBlur = e => {
    setIsFocus(false)
  }

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const onMouseDown = e => {
    e.preventDefault()
    textareaRef.current.focus()
  }

  return (
    <TextareaWrapper
      disabled={disabled}
      fullWidth={fullWidth}
      isFocus={isFocus}
      isHover={isHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    >
      <TextareaElement
        defaultValue={defaultValue}
        ref={textareaRef}
        placeholder={placeholder}
        disabled={disabled}
        onMouseDown={e => {
          // If selected directly don't refocus.
          e.stopPropagation()
        }}
        type={type}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        id={id}
      />
    </TextareaWrapper>
  )
}

export default Textarea
